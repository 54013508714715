"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueriesStore = exports.PODTypes = exports.PricingConditions = exports.PODStage3Parts = exports.IPCTypes = exports.FleetQueryAuthoriserIDs = exports.FleetQueryAuthorisers = exports.PODQueryResults = exports.PODQueryReasons = exports.FleetQueryReasons = exports.PriceQueryCauses = exports.PriceQueryReasons = exports.QueryActions = exports.ProductTypes = exports.CountriesFilter = exports.Countries = exports.CreditTypes = exports.QueryTypesText = exports.QueryTypes = exports.QueryStatus = void 0;
var Store_1 = require("./Store");
var QueryStatus;
(function (QueryStatus) {
    QueryStatus["ACTIVE"] = "Active";
    QueryStatus["COMPLETED"] = "Completed";
    QueryStatus["CLOSED"] = "Closed";
})(QueryStatus = exports.QueryStatus || (exports.QueryStatus = {}));
var QueryTypes;
(function (QueryTypes) {
    QueryTypes[QueryTypes["PRICE"] = 1] = "PRICE";
    QueryTypes[QueryTypes["FLEET"] = 2] = "FLEET";
    QueryTypes[QueryTypes["POD"] = 3] = "POD";
})(QueryTypes = exports.QueryTypes || (exports.QueryTypes = {}));
var QueryTypesText;
(function (QueryTypesText) {
    QueryTypesText["ALL"] = "All Query Types";
    QueryTypesText["PRICE"] = "Price";
    QueryTypesText["FLEET"] = "Fleet";
    QueryTypesText["POD"] = "POD";
})(QueryTypesText = exports.QueryTypesText || (exports.QueryTypesText = {}));
var CreditTypes;
(function (CreditTypes) {
    CreditTypes["CREDIT"] = "Credit";
    CreditTypes["DEBIT"] = "Debit";
    CreditTypes["BOTH"] = "Both";
})(CreditTypes = exports.CreditTypes || (exports.CreditTypes = {}));
var Countries;
(function (Countries) {
    Countries["UK"] = "UK";
    Countries["IR"] = "IR";
})(Countries = exports.Countries || (exports.Countries = {}));
var CountriesFilter;
(function (CountriesFilter) {
    CountriesFilter["ALL"] = "All Countries";
    CountriesFilter["UK"] = "UK";
    CountriesFilter["IR"] = "IR";
})(CountriesFilter = exports.CountriesFilter || (exports.CountriesFilter = {}));
var ProductTypes;
(function (ProductTypes) {
    ProductTypes["PC4"] = "PC4";
    ProductTypes["MC"] = "MC";
    ProductTypes["TBR"] = "TBR";
    ProductTypes["RET"] = "RET";
    ProductTypes["OTR"] = "OTR";
    ProductTypes["IND"] = "IND";
    ProductTypes["AG"] = "AG";
    ProductTypes["OTHER_CONSUMER"] = "Other (Consumer)";
    ProductTypes["OTHER_COMMERCIAL"] = "Other (Commercial)";
})(ProductTypes = exports.ProductTypes || (exports.ProductTypes = {}));
var QueryActions;
(function (QueryActions) {
    QueryActions["CREDIT"] = "Credit";
    QueryActions["DEBIT"] = "Debit";
    QueryActions["CREDIT_DEBIT"] = "Credit / Debit";
})(QueryActions = exports.QueryActions || (exports.QueryActions = {}));
var PriceQueryReasons;
(function (PriceQueryReasons) {
    PriceQueryReasons["LIST_PRICE_INCORRECT"] = "List Price Incorrect";
    PriceQueryReasons["PROMO_CODE"] = "Promo Code";
    PriceQueryReasons["SALES_ORDER_PRICE_CHANGE"] = "Sales Order Price Change";
    PriceQueryReasons["PERMANENT_PRICE_INCORRECT"] = "Permanent net price incorrect";
    PriceQueryReasons["CUSTOMER_DISCOUNT"] = "Customer Discount";
    PriceQueryReasons["WRONG_PRICE_LIST"] = "Wrong Price List Issued";
    PriceQueryReasons["GOOD_WILL"] = "Good Will";
    PriceQueryReasons["INVOICED_INCORRECT_ACCOUNT"] = "Invoiced on incorrect account";
    PriceQueryReasons["REJECTED"] = "Rejected";
    PriceQueryReasons["ADMIN_ERROR"] = "Admin Error";
})(PriceQueryReasons = exports.PriceQueryReasons || (exports.PriceQueryReasons = {}));
var PriceQueryCauses;
(function (PriceQueryCauses) {
    PriceQueryCauses["SALES_PROCESS"] = "Sales - Process not followed";
    PriceQueryCauses["SALES_ADMIN"] = "Sales Admin -  Error of Entry / delayed actioning";
    PriceQueryCauses["MARKETING_OPPS"] = "Marketing Opps - incorrect instruction / delayed actioning";
    PriceQueryCauses["CUSTOMER_ENTRY_ERROR"] = "Customer Service - Error of Entry";
    PriceQueryCauses["CUSTOMER_PRICE_REFRESH"] = "Customer Service - Price Refresh error";
    PriceQueryCauses["BUSINESS_AGREED"] = "Business agreed a commercial decision";
    PriceQueryCauses["SALES_COMMUNICATION"] = "Sales - Customer communication error";
    PriceQueryCauses["FINANCE_APPROVAL"] = "Finance \u2013 pricing approval took more than 24 hours";
    PriceQueryCauses["REJECTED"] = "Rejected";
})(PriceQueryCauses = exports.PriceQueryCauses || (exports.PriceQueryCauses = {}));
var FleetQueryReasons;
(function (FleetQueryReasons) {
    FleetQueryReasons["FC_KEY_ACCOUNT"] = "Full Credit - Agreed By Key Account Manager";
    FleetQueryReasons["FC_BRIDGESTONE_RELEASE_ERROR"] = "Full Credit - Bridgestone Released in Error";
    FleetQueryReasons["FC_FOC_CHARGE"] = "Full Credit - Charge should be FOC for the fleet";
    FleetQueryReasons["FC_DUPLICATE"] = "Full Credit - Duplicate Credit/Invoice";
    FleetQueryReasons["FC_FLEET_RELEASE_ERROR"] = "Full Credit - Fleet Released in Error";
    FleetQueryReasons["FC_PPK_CHARGE"] = "Full Credit - PPK Charge Incorrect";
    FleetQueryReasons["FC_VEHICLE_NO_CONTRACT"] = "Full Credit - Vehicle not on contract at time of job";
    FleetQueryReasons["FC_INVOICE_CURRENCY"] = "Full Credit and Reinvoice, job processed in incorrect currency";
    FleetQueryReasons["FC_INVOICE_FLEET"] = "Full Credit and Reinvoice, job processed on incorrect fleet";
    FleetQueryReasons["FC_GOOD_WILL"] = "Full Credit as Good Will";
    FleetQueryReasons["FC_INVOICE_PRICE"] = "Full Credit and Reinvoice, job released with incorrect price";
    FleetQueryReasons["FC_DEALER_INCORRECT"] = "Full Credit \u2013 Job processed by dealer with incorrect details";
    FleetQueryReasons["PC_FLEET_RELEASE_ERROR"] = "Partial Credit - Fleet Released in Error";
    FleetQueryReasons["PC_INCORRECT_CALLOUT"] = "Partial Credit - Incorrect Callout Day/Night Charged";
    FleetQueryReasons["PC_INCORRECT_SERVICE"] = "Partial Credit - Incorrect Service Charged";
    FleetQueryReasons["PC_PAYG_PRICE"] = "Partial Credit - PAYG Price Incorrect";
    FleetQueryReasons["PC_SPECIAL_PRICE"] = "Partial Credit - Special Price agreed by Key Account Manager";
    FleetQueryReasons["PC_INCORRECT_TYRE"] = "Partial Credit = Incorrect Tyre Charged";
    FleetQueryReasons["PC_GOOD_WILL"] = "Partial Credit as Good Will";
    FleetQueryReasons["PO_ISSUE"] = "PO Issue";
    FleetQueryReasons["QUERY_CONTROL_ERROR"] = "Query Control Error - Credit/Debit raised for Incorrect Value";
    FleetQueryReasons["REFUSED_CREDIT"] = "Refused For Credit";
    FleetQueryReasons["VAT_INVOICE_ERROR"] = "VAT Invoicing Error";
    FleetQueryReasons["INCORRECT_ORDER_NUMBER"] = "Incorrect order number";
    FleetQueryReasons["REVERSAL_CREDIT"] = "Reversal Of Previous Credit";
})(FleetQueryReasons = exports.FleetQueryReasons || (exports.FleetQueryReasons = {}));
var PODQueryReasons;
(function (PODQueryReasons) {
    PODQueryReasons["SHORT"] = "Short";
    PODQueryReasons["EXTRA"] = "Extra";
    PODQueryReasons["SHORT_EXTRA"] = "Short & Extra";
    PODQueryReasons["UNSUPERVISED"] = "Unsupervised - Qty Diff";
    PODQueryReasons["POD_SCANNER"] = "POD not on scanner";
    PODQueryReasons["SIGNATURE"] = "Signature not recognised";
    PODQueryReasons["REFUSED_DELIVERY"] = "Refused delivery";
    PODQueryReasons["WRONG_TYRE"] = "Wrong tyre";
    PODQueryReasons["DR_CR_CORRECTION"] = "DR/CR Correction";
    PODQueryReasons["OTHER"] = "Other";
})(PODQueryReasons = exports.PODQueryReasons || (exports.PODQueryReasons = {}));
var PODQueryResults;
(function (PODQueryResults) {
    PODQueryResults["CREDIT"] = "Credit Raised";
    PODQueryResults["DUMMY"] = "Dummy Raised";
    PODQueryResults["CR_DR_RAISED"] = "CR & DR Raised";
    PODQueryResults["POD_SENT"] = "POD Sent to customer";
    PODQueryResults["CLAIM_CUSTOMER"] = "Claim refused, customer informed";
    PODQueryResults["CLAIM_GOODWILL"] = "Claim refused, goodwill";
    PODQueryResults["QUERY_CANCELLED"] = "Query cancelled";
    PODQueryResults["OTHER"] = "Other";
})(PODQueryResults = exports.PODQueryResults || (exports.PODQueryResults = {}));
var FleetQueryAuthorisers;
(function (FleetQueryAuthorisers) {
    FleetQueryAuthorisers["PAUL"] = "Paul Sheehan";
    FleetQueryAuthorisers["GREG"] = "Greg Melville";
    FleetQueryAuthorisers["WENDY"] = "Wendy Doyle";
})(FleetQueryAuthorisers = exports.FleetQueryAuthorisers || (exports.FleetQueryAuthorisers = {}));
var FleetQueryAuthoriserIDs;
(function (FleetQueryAuthoriserIDs) {
    FleetQueryAuthoriserIDs[FleetQueryAuthoriserIDs["PAUL"] = 62] = "PAUL";
    FleetQueryAuthoriserIDs[FleetQueryAuthoriserIDs["GREG"] = 47] = "GREG";
    FleetQueryAuthoriserIDs[FleetQueryAuthoriserIDs["WENDY"] = 30] = "WENDY";
})(FleetQueryAuthoriserIDs = exports.FleetQueryAuthoriserIDs || (exports.FleetQueryAuthoriserIDs = {}));
var IPCTypes;
(function (IPCTypes) {
    IPCTypes["RETURNS"] = "Returns";
    IPCTypes["ORDER"] = "Order";
    IPCTypes["DUMMY"] = "Dummy Order";
})(IPCTypes = exports.IPCTypes || (exports.IPCTypes = {}));
var PODStage3Parts;
(function (PODStage3Parts) {
    PODStage3Parts["WAREHOUSE_1"] = "warehouse1";
    PODStage3Parts["CREDIT"] = "credit";
    PODStage3Parts["CUSTOMER_SERVICES"] = "customerServices";
    PODStage3Parts["WAREHOUSE_2"] = "warehouse2";
})(PODStage3Parts = exports.PODStage3Parts || (exports.PODStage3Parts = {}));
var PricingConditions;
(function (PricingConditions) {
    PricingConditions["CONDITION_1"] = "Condition 1";
    PricingConditions["CONDITION_2"] = "Condition 2";
    PricingConditions["CONDITION_3"] = "Condition 3";
})(PricingConditions = exports.PricingConditions || (exports.PricingConditions = {}));
var PODTypes;
(function (PODTypes) {
    PODTypes["SHORTAGE"] = "Shortage";
    PODTypes["NON_DELIVERY"] = "Non-Delivery";
    PODTypes["REFUSED"] = "Refused";
    PODTypes["MISSING_POD"] = "Missing POD";
    PODTypes["DIRECT"] = "Direct";
    PODTypes["STOCK_INVESTIGATION"] = "Stock Investigation";
    PODTypes["DUMMY"] = "Dummy";
})(PODTypes = exports.PODTypes || (exports.PODTypes = {}));
var QueriesStore = /** @class */ (function (_super) {
    __extends(QueriesStore, _super);
    function QueriesStore(data) {
        var _this = _super.call(this, data) || this;
        _this.setData = function (data) {
            _this.data = data;
        };
        return _this;
    }
    return QueriesStore;
}(Store_1.Store));
exports.QueriesStore = QueriesStore;
